// src/App.js
import React from 'react';
import './styles/App.css'; // Importa los estilos básicos

// Importa los componentes
import Dog from './components/Dog';
import Competition from './components/Competition';

function App() {
  return (
    <div className="container">
      <h1>Juego de Concurso de Perros</h1>
      <Dog />
      <Competition />
    </div>
  );
}

export default App;

// src/components/Dog.js
import React, { useState, useEffect } from 'react';
import happyDogImage from '../images/dog_feliz.jpg';
import neutralDogImage from '../images/dog3.jpg'; // Importa la imagen del perro con expresión neutral
import sadDogImage from '../images/dog_triste.jpg';

const Dog = () => {
  // Estado del perro
  const [happiness, setHappiness] = useState(100); // Establece la felicidad inicial en 100
  const [energy, setEnergy] = useState(50);
  const [expression, setExpression] = useState('happy');

  // Función para disminuir la felicidad gradualmente con el tiempo
  useEffect(() => {
    const happinessTimer = setInterval(() => {
      setHappiness(prevHappiness => Math.max(prevHappiness - 1, 0)); // Disminuye la felicidad en 1 cada segundo, con un mínimo de 0
    }, 1000); // Ejecuta la función cada segundo

    return () => clearInterval(happinessTimer); // Limpia el temporizador cuando el componente se desmonta
  }, []);

  // Actualiza la expresión del perro según la energía y la felicidad
  useEffect(() => {
    if (energy < 30 || happiness === 0) {
      setExpression('sad');
    } else if (energy < 70) {
      setExpression('neutral');
    } else {
      setExpression('happy');
    }
  }, [energy, happiness]);

  // Función para alimentar al perro
  const feedDog = () => {
    if (energy < 100) setEnergy(energy + 10);
    if (happiness < 100) setHappiness(happiness + 5);
  };

  // Función para jugar con el perro
  const playWithDog = () => {
    if (energy > 0) setEnergy(energy - 10);
    if (happiness < 100) setHappiness(happiness + 10);
  };

  return (
    <div className="dog-container">
      <h2>¡Bienvenido a tu Perro Virtual!</h2>
      {expression === 'happy' && <img src={happyDogImage} alt="Perro Virtual Alegre" />}
      {expression === 'neutral' && <img src={neutralDogImage} alt="Perro Virtual Neutral" />}
      {expression === 'sad' && <img src={sadDogImage} alt="Perro Virtual Triste" />}
      <p>Nivel de Felicidad: {happiness}</p>
      <p>Nivel de Energía: {energy}</p>
      <button onClick={feedDog}>Alimentar</button>
      <button onClick={playWithDog}>Jugar</button>
    </div>
  );
}

export default Dog;

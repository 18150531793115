// src/components/Competition.js
import React from 'react';

const Competition = () => {
  return (
    <div>
      <h2>¡Competiciones Disponibles!</h2>
      <ul>
        <li>Concurso de Belleza</li>
        <li>Carrera de Obstáculos</li>
        <li>Lanzamiento de Frisbee</li>
        {/* Añade más competiciones según sea necesario */}
      </ul>
    </div>
  );
}

export default Competition;
